<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Pendidikan : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- pend_nama univ -->
          <b-form-group label="Universitas" label-for="pend_nama_univ">
            <validation-provider #default="{ errors }" name="pend_nama_univ">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="pend_nama_univ"
                  placeholder="Search univ"
                  v-model="filter.pend_nama_univ"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- pend negara -->
          <b-form-group label="Negara" label-for="pend_negara">
            <validation-provider #default="{ errors }" name="pend_negara">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="pend_negara"
                  placeholder="Search negara"
                  v-model="filter.pend_negara"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- pend negara -->
          <b-form-group label="Nama Dokter" label-for="nama_dokter">
            <validation-provider #default="{ errors }" name="nama_dokter">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="nama_dokter"
                  placeholder="Search nama dokter"
                  v-model="filter.orang_nama_lengkap"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- pend nama studi -->
          <b-form-group label="Program Studi" label-for="pend_nama_studi">
            <validation-provider #default="{ errors }" name="pend_nama_studi">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="pend_nama_studi"
                  placeholder="Search program studi"
                  v-model="filter.pend_nama_studi"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- pend nomor ijazah -->
          <b-form-group label="Nomor Ijazah" label-for="pend_nomor_ijazah">
            <validation-provider #default="{ errors }" name="pend_nomor_ijazah">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="pend_nomor_ijazah"
                  placeholder="Search nomor ijazah"
                  v-model="filter.pend_nomor_ijazah"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- pend nomor ijazah -->
          <b-form-group label="Tanggal Dibuat" label-for="tanggal_dibuat">
            <validation-provider #default="{ errors }" name="tanggal_dibuat">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="tanggal_dibuat"
                  placeholder="Search Tanggal Dibuat"
                  v-model="filter.pend_nomor_ijazah"
                  type="date"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="reloadPage"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="modalVerifikasiData(data.item)">
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Edit/Verifikasi</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- edit jadwal krip-->
    <b-modal
      id="modal-verifikasi-pendidikan"
      title="Edit/Verifikasi"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <table style="width: 100%">
          <tr>
            <td>File</td>
            <td>:</td>
            <td>
              <!-- mau dibenerin-->
              <img
                :src="updateVerifikasiPendidikan.pend_ijazah_file"
                alt=""
                width="200"
                height="200"
              />
            </td>
          </tr>
        </table>

        <br />
        <b-form-input
          id="id"
          v-model="updateVerifikasiPendidikan.id"
          placeholder="id"
          hidden
        />
        <b-form-group label="Negara" label-for="vue-select">
          <b-form-input
            id="no_str"
            v-model="updateVerifikasiPendidikan.pend_negara"
            placeholder="Negara"
          />
        </b-form-group>
        <b-form-group label="Nama Universitas" label-for="nama_universitas">
          <b-form-input
            id="nama_universitas"
            v-model="updateVerifikasiPendidikan.pend_nama_univ"
            placeholder="nama univ"
          />
        </b-form-group>
        <b-form-group label="Nama Program Studi" label-for="program_studi">
          <b-form-input
            id="nama_program_studio"
            v-model="updateVerifikasiPendidikan.pend_nama_studi"
            placeholder="nama program studi"
          />
        </b-form-group>
        <b-form-group label="Tahun Masuk" label-for="tahun_masuk">
          <b-form-input
            id="tahun_masuk"
            v-model="updateVerifikasiPendidikan.pend_thn_masuk"
            placeholder="tahun masuk"
          />
        </b-form-group>
        <b-form-group label="Tahun Keluar" label-for="tahun_keluar">
          <b-form-input
            id="tahun_keluar"
            v-model="updateVerifikasiPendidikan.pend_thn_keluar"
            placeholder="tahun keluar"
          />
        </b-form-group>
        <b-form-group label="Nomor Ijazah" label-for="nomor_ijazah">
          <b-form-input
            id="nomor_ijazah"
            v-model="updateVerifikasiPendidikan.pend_nomor_ijazah"
            placeholder="nomor ijazah"
          />
        </b-form-group>
        <b-form-group label="Level Pendidikan" label-for="vue-select">
          <v-select
            id="level_pendidikan"
            v-model="updateVerifikasiPendidikan.pendlevel_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionLevelPendidikan"
            placeholder="level_pendidikan.."
          />
        </b-form-group>
        <b-form-group label="terferifikasi" label-for="vue-select">
          <v-select
            id="terferifikasi"
            v-model="updateVerifikasiPendidikan.pend_editable"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="optionVerifikasi"
            placeholder="terferifikasi.."
          />
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="verifikasiDataPendidikan"
        >
          Simpan
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BFormFile,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormTimepicker,
  BFormSpinbutton,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BCardText,
    BFormTimepicker,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      rows: 0,
      perPage: 20,
      // lihat peserta
      pesertaCurrentPage: 1,
      pesertaRows: 0,
      pesertaPerPage: 20,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "Id" },
        [{ key: "pend_editable", label: "terverifikasi" }],
        [{ key: "orang_id.orang_nama_lengkap", label: "Nama Dokter" }],
        [{ key: "pend_nomor_ijazah", label: "nomor ijazah" }],
        [{ key: "pend_negara", label: "negara asal universitas" }],
        [{ key: "pend_nama_univ", label: "nama universitas" }],
        [{ key: "pend_nama_studi", label: "program studi" }],
        [{ key: "pend_thn_masuk", label: "tahun masuk" }],
        [{ key: "pend_thn_keluar", label: "tahun keluar" }],
        [{ key: "pendlevel_id", label: "level" }],
        [{ key: "created_at", label: "Tanggal Dibuat" }],
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */

      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],
      search: null,
      updateVerifikasiPendidikan: {},
      optionVerifikasi: ["true", "false"],
      optionLevelPendidikan: [1, 2, 3, 4, 5, 6],
      formatted: "",
      selected: "",
      filter: {
        pend_nama_univ: null,
        pend_negara: null,
        pend_nama_studi: null,
        pend_nomor_ijazah: null,
        orang_nama_lengkap: null,
        created_at: null,
      },
    };
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getPendidikan();
      } else {
        this.currentPage = 1;
      }
    },
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },

    modalVerifikasiData(item) {
      this.updateVerifikasiPendidikan = item;
      this.$bvModal.show("modal-verifikasi-pendidikan");
    },
    reloadPage() {
      location.reload();
    },

    async getPendidikan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.pendidikanIdi.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data pendidikan");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async verifikasiDataPendidikan() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        var ids = this.updateVerifikasiPendidikan.id;

        let body = {
          pend_negara: this.updateVerifikasiPendidikan.pend_negara,
          pend_nama_univ: this.updateVerifikasiPendidikan.pend_nama_univ,
          pend_nama_studi: this.updateVerifikasiPendidikan.pend_nama_studi,
          pend_thn_masuk: this.updateVerifikasiPendidikan.pend_thn_masuk,
          pend_thn_keluar: this.updateVerifikasiPendidikan.pend_thn_keluar,
          pend_nomor_ijazah: this.updateVerifikasiPendidikan.pend_nomor_ijazah,
          pendlevel_id: this.updateVerifikasiPendidikan.pendlevel_id,
          pend_editable: this.updateVerifikasiPendidikan.pend_editable,
        };
        const { data } = await API.pendidikanIdi.updateData(ids, body);
        this.handleMsgSuccess("Berhasil Verifikasi Data Pendidikan");
        this.refreshPage();
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi");
      } finally {
        this.$bvModal.hide("modal-verifikasi-pendidikan");
        this.clearSelected();
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getPendidikan();
  },
  watch: {
    currentPage() {
      this.getPendidikan();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
